@media (max-width:1500px) {
	.container { width: 1200px; }
	h2 { font-size: 28px; line-height: 32px; }
	h3 { font-size: 24px; }
	.navbar-form .input-group { width: 100%; }
	.header-address { padding: 20px 5px 20px; }
	.header-info p.call { font-size: 32px; line-height: 40px; }
	.navigation ul li { padding: 10px 0; }
	.navigation ul li a { padding: 10px; font-size: 18px; }
	.search-form { padding: 10px 15px; }
	.banner-section { padding: 40px 0; }
	.banner-content { padding: 15px 25px 20px; }
	.banner-content .banner-title { font-size: 32px; }
	.ask-questions:first-child { padding-bottom: 25px; margin-bottom: 25px; }
	.ask-questions h3 { font-size: 24px; }
	.categories-section { padding: 45px 0; }
	.home-categories .row { padding-top: 35px; }
	.list-category .categories h2 { font-size: 20px; line-height: 26px; }
	.intro-block .col-md-4 { padding-left: 40px; }
	.intro-sidebar a.button { font-size: 18px; padding: 15px 0; }
	.footer-bottom,.site-footer ul li, .site-footer ul li a { font-size: 14px; }

	.category-sidebar a.button { font-size: 18px; }
	.product-category { margin-bottom: 50px; }
	.product-category h3 { font-size: 20px; }

	.woocommerce div.product form.cart div.quantity { margin: 0 4px 0 0px; }

    .product-thumb img { height: 176px; }
}

@media (max-width:1280px) {
	.container { width: 960px; }
	h2 { font-size: 32px; line-height: 38px; }
	h3 { font-size: 20px; line-height: 26px; }
	a.button { font-size: 16px; line-height: 20px; padding: 14px 30px; }
	input::-moz-placeholder{ font-size: 14px; }
	input::-webkit-input-placeholder{ font-size: 14px; }
	input::-ms-input-placeholder{ font-size: 14px; }
	input:-ms-input-placeholder{ font-size: 14px; }
	.woo-menu ul li:first-child { margin-right: 25px; }
	.woo-menu ul li, .woo-menu ul li a { font-size: 16px; }
	.header-info p.call { font-size: 28px; line-height: 32px; }
	.navigation ul li a { padding: 10px; font-size: 14px; }
	.banner-right { padding: 25px 20px; font-size: 16px; line-height: 20px; }
	.ask-questions h3 { font-size: 22px; line-height: 24px; }
	.banner-content { font-size: 16px; line-height: 20px; }
	.banner-content .banner-title { font-size: 28px; margin: 10px 0; }
	.categories-section { padding: 30px 0 10px; }
	.list-category { width: 33.33%; }
	.intro-section { padding: 30px 0 50px; }
	.intro-block .col-md-4 { padding-left: 15px; }
	.sidebar-content { font-size: 16px; line-height: 20px; padding: 25px 20px 25px; }
	.intro-sidebar a.button { font-size: 16px; padding: 10px 0; }

	.content-detail-block { padding: 50px 0 30px; }

    .productlist-section, .product-detail-block { padding: 50px 0 30px; }
	.product-category { width: 33.33%; }
	.productlist-section .top-nav, .product-detail-block .top-nav { font-size: 26px; }
	.productlist-section .top-nav, .product-detail-block .top-nav { margin: 0 0 50px; }
	.ask-questions h3 { font-size: 20px; }

	.woocommerce .product-detail-block .product_title { font-size: 30px; margin: 0 0 40px; }
	.woocommerce div.product form.cart div.quantity {  margin: 0 4px 25px 0; }
	.woocommerce .product-detail-block .single_variation_wrap p.price, .woocommerce .product-detail-block .single_simple_wrap p.price { font-size: 20px; }
	.woocommerce div.product form.cart .button { font-size: 18px; line-height: 20px; padding: 15px 20px; }
	.woocommerce .upsells.products .product-category { margin: 70px 0; }
	.single-product .product-detail-block .top-nav { margin: 0px 0 30px; }
	.site-footer .col-sm-6 { width: 50%; }

	ul.sub-menu li a { font-size: 14px !important; line-height: 16px; }
	.woocommerce.single-product span.onsale { top: 115px; }

    .product-thumb img { height: 189px; }

}
@media (max-width:991px) {
	.container { width: 740px; }
	body { font-size: 16px; line-height: 24px; }
	h2 { font-size: 26px; line-height: 30px; }
	h3 { font-size: 18px; line-height: 20px; }
	input::-moz-placeholder{ font-size: 12px; }
	input::-webkit-input-placeholder{ font-size: 12px; }
	input::-ms-input-placeholder{ font-size: 12px; }
	input:-ms-input-placeholder{ font-size: 12px; }
	.woo-menu ul li:first-child { margin-right: 10px; }
	.woo-menu ul li, .woo-menu ul li a { font-size: 14px; }
	.woo-menu ul li a { padding: 5px 15px 4px; }
	.header-address { padding: 10px 5px; line-height: 16px; font-size: 14px; }
	.header-info p.call { font-size: 20px; line-height: 26px; }
	.navigation { padding-right: 0; }
	.navigation ul li a { padding: 5px; font-size: 12px; }
	.search-form input.form-control, .search-form .input-group .input-group-btn .btn { font-size: 12px; height: 30px; padding: 0 10px; line-height: 30px; }
	.input-group .input-group-btn .btn .fa {  font-size: 14px; }
	.banner-section { padding: 25px 0; }
	.banner-right { margin-top: 25px; }
	.list-category .categories h2 { font-size: 20px; line-height: 26px; }
	.intro-section { padding: 20px 0 35px; }
	.intro-sidebar { margin: 30px 0 0 0; }
	.product-categories ul li { padding-right: 0; }
	.site-footer { padding: 15px 0; }

	.product-category { width: 50%; }
	.product-category h3 { font-size: 18px; }
	.product-list-block .category-sidebar { padding-left: 0; }
	.ask-questions h3 { font-size: 18px; line-height: 20px; }
	.intro-sidebar a.button { font-size: 14px; padding: 12px 0; line-height: 18px; }
	.productlist-section .top-nav, .product-detail-block .top-nav { font-size: 22px; margin: 0 0 40px; }
	.woocommerce div.product.product-category { margin-bottom: 30px; }
	.product-list-block .woocommerce-loop-product__title, .up-sells .woocommerce-loop-product__title { padding-top: 10px; }

	.woocommerce .product-detail-block .product_title { font-size: 26px; margin: 0 0 30px; }
	.woocommerce .product-detail-block .single_variation_wrap p.price, .woocommerce .product-detail-block .single_simple_wrap p.price { font-size: 16px; margin-right: 10px; }
	.woocommerce div.product form.cart .button { font-size: 16px; line-height: 18px; padding: 10px 15px; }
	.woocommerce .upsells.products .product-category { margin: 50px 0; }

	.navigation ul li.open > ul.sub-menu, .navigation ul li:hover > ul.sub-menu { padding: 0 0 5px; min-width: 150px; }
	ul.sub-menu li a { font-size: 12px !important; line-height: 12px; }
	.woocommerce.single-product span.onsale { width: 40px; height: 40px; line-height: 45px; font-size: 14px; top: 95px; }

    .product-thumb img { height: 222px; }

}
@media (max-width:767px) {
	.container { width: 100%; padding-left: 20px; padding-right: 20px; }
	.row { margin-left: -20px; margin-right: -20px; }
	body { font-size: 16px; line-height: 22px; }
	h2 { font-size: 28px; line-height: 34px; }
	a.button { font-size: 18px; line-height: 20px; padding: 17px 40px; }
	.ask-questions h3,h3 {    font-size: 22px; line-height: 26px; }
	.ask-questions h3 { margin: 5px 0 10px; }
	h4 { font-size: 16px; line-height: 20px; }
	.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 { padding-left: 20px; padding-right: 20px; }
	.mobile-bar { background: #0E3B5F; color: #FFF; }
	.mobile-bar ul { margin: 0; padding: 0; list-style: none; text-align: center; }
	.mobile-bar ul li { display: inline-block; margin: 0 15px; }
	.mobile-bar ul li a { color: #FFF; font-size: 24px; padding: 10px; display: inline-block; }
	.logo { background: #216e99; padding: 20px 20px 30px; text-align: center; }
	.header-right { display: none; }
	.search-form input.form-control, .search-form .input-group .input-group-btn .btn { font-size: 14px; height: 35px; padding: 0 15px; line-height: 35px; }
	.search-form { padding: 12px 20px; }
	.navigation { position: fixed; right: -230px; top: 0; height: 100%; width: 230px; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; z-index: 19; background: #0e3b5f; text-align: center; overflow: auto; padding: 30px 0;
    margin: 0; overflow-x: hidden; -webkit-box-shadow: inset 5px 0px 10px rgba(0, 0, 0, 0.23); box-shadow: inset 5px 0px 10px rgba(0, 0, 0, 0.23) }
	.open-menu .navigation { right: 0; }

    /* MEGA MENU */
    .navigation ul li .mega-container { left: auto; position: static; top: auto; padding: 0; }
    .navigation ul li.open > .mega-container, .navigation ul li:hover > .mega-container { display: block; }
    .navigation ul li .mega-container ul.sub-menu li ul.sub-menu { display: none; }
    .navigation ul li .mega-container ul.sub-menu li.open > ul.sub-menu, .navigation ul li .mega-container ul.sub-menu li:hover > ul.sub-menu { display: block; }

	.site { -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; position: relative; left: 0; }
	.open-menu .site { left: -230px; }
	.open-menu .fa-bars:before { content: "\f00d"; }
	.navigation ul li { padding: 0; display: block; text-align: left; }
	.navigation ul li:first-child a, .navigation ul li:last-child a,.navigation ul li a { padding: 7px 15px; font-size: 16px; line-height: 20px; }
	.banner-section { padding: 30px 0 0; background: #FFF; background-image: none !important; }
	.bx-wrapper .bx-viewport { border-radius: 0; box-shadow: none; }
	ul.bxslider li { background-image: none !important; }
	ul.bxslider li img { opacity: 1; }
	.banner-content { position: relative; top: 0; bottom: 0; left: 0; right: 0; background: transparent; font-size: 14px; line-height: 16px; padding: 0; text-align: center; }
	.banner-content .banner-title { font-size: 20px; margin: 13px 0 10px; line-height: 22px;  }
	.banner-section .col-md-4.eq-height { padding: 0; }
	.banner-right { background-image: none; background: #0e3b5f; box-shadow: none; margin-top: 30px; }
	.categories-title,.categories-title h2 { padding: 0; }
	.categories-title:before { content: none; }
	.list-category { width: 100%; margin-bottom: 0; }
	.list-category .categories { padding: 0; background: transparent; text-align: left; }
	.home-categories .row { margin-left: -20px; margin-right: -20px; }
	.home-categories .category-image { display: none;}
	.list-category .categories h2 { font-size: 24px; line-height: 26px; text-transform: uppercase; margin: 0 0 10px; padding-left: 30px; position: relative; }
	.categories-section { padding: 30px 0 50px; }
	.intro-block a.button { margin-top: 10px; }
	.intro-sidebar { margin: 35px 0 0 0; }
	.list-category .categories h2:before { content: ""; position: absolute; left: 10px; top: 0%; width: 6px; height: 6px; background: #0E3B5F; border-radius: 50%; margin-top: 10px; }
	.home-categories .row { padding-top: 30px; }
	.intro-section { padding: 20px 0 30px; }
	.intro-block { padding: 15px 0 10px; }
	.intro-block .col-md-4 { padding: 0; }
	.intro-sidebar { box-shadow: none; }
	.intro-sidebar a.button { font-size: 20px; padding: 20px 0; word-break: break-word; white-space: normal;line-height: 24px; margin-top: 0; }
	.footer-links h4 { margin-bottom: 5px; }
	.footer-bottom, .site-footer ul li, .site-footer ul li a { font-size: 11px; }
	.site-footer .col-sm-6 { margin-bottom: 25px; width: 100%; }
	.footer-bottom { padding: 0 0 10px; }

	.content-detail-block { padding: 40px 0 25px; }

    .productlist-section, .product-detail-block { padding: 40px 0 25px; }
	.productlist-section .top-nav, .product-detail-block .top-nav { font-size: 30px; margin: 0 0 30px; text-align: center; line-height: 37px; }
	.productlist-section .top-nav a, .product-detail-block .top-nav a { display: none; }
	.product-list-block .col-sm-8 { margin-bottom: 70px; }
	.product-list-block .sidebar-content { padding: 30px 20px 35px; }
	.product-list-block .banner-right { margin-top: 25px; padding: 20px 20px 30px ; }
	.product-list-block .banner-right .ask-questions:first-child { padding-bottom: 22px; margin-bottom: 22px; }
	.product-category { width: 100%; margin-bottom: 0; text-align: left; }
	.category-image { display: none; }
	.product-category h3 { font-size: 24px; line-height: 26px; text-transform: uppercase; margin: 0 0 10px; padding-left: 30px; position: relative; }
	.product-category h3:before { content: ""; position: absolute; left: 8px; top: 0%; width: 6px; height: 6px; background: #0E3B5F; border-radius: 50%;
    margin-top: 10px; }
	.product-list-block .col-sm-4 { padding: 0; }
	.product-list-block .woocommerce-loop-product__title { padding-top: 10px; }

	.tax-product_cat.woocommerce div.product.product-category {  text-align: center; margin-bottom: 40px; }
	.tax-product_cat .category-image {  display: block; margin-bottom: 25px; }
	.tax-product_cat .productlist-section { padding: 65px 0 40px; }
	.tax-product_cat .productlist-section .top-nav { margin: 0 0 50px; }
	.product-list-block .woocommerce-loop-product__title { font-size: 18px; }

	.woocommerce .product-detail-block .product_title { font-size: 28px; text-align: center; }
	.woocommerce #content div.product div.images, .woocommerce div.product div.images, .woocommerce-page #content div.product div.images, .woocommerce-page div.product div.images,.woocommerce #content div.product div.summary, .woocommerce div.product div.summary, .woocommerce-page #content div.product div.summary, .woocommerce-page div.product div.summary { float: left; width: 100%; font-size: 20px; line-height: 28px; }
	.woocommerce #content div.product div.summary, .woocommerce div.product div.summary, .woocommerce-page #content div.product div.summary, .woocommerce-page div.product div.summary { margin-bottom: 0; }
	.product-detail-block .col-sm-4 { float: left; width: 100%; margin: 0 0 20px;  }
	.woocommerce div.product form.cart .variations { margin: 0; display: block; width: 100% !important; float: none; max-width: 100%; margin: 0; }
	.woocommerce div.product form.cart div.quantity { display: block; width: 100%; float: none; }
	.woocommerce div.product form.cart .reset_variations { display: block; }
	.woocommerce .quantity .qty {  width: 180px; box-shadow: none; -webkit-appearance: none; }
	.woocommerce .product-detail-block .single_variation_wrap p.price, .woocommerce .product-detail-block .single_simple_wrap p.price { font-size: 24px; margin-right: 0; margin: 10px 0; text-align: center; display: block; }
	.woocommerce-variation-add-to-cart { text-align: center; }
	.woocommerce div.product form.cart .single_variation_wrap .button, .woocommerce div.product form.cart .single_simple_wrap .button { font-size: 24px; line-height: 26px; padding: 19px 30px; margin-top: 10px; }
	.product-detail-block .col-sm-4 { padding: 0; }
	.up-sells { margin: 0 0 30px; }
	.woocommerce .upsells.products .product-category { text-align: center; }
	.upsells.products .product-category .category-image { display: block; }

	.productlist-section .top-nav li:after, .product-detail-block .top-nav li:after { content: none; }

	.navigation ul li.open > ul.sub-menu, .navigation ul li:hover > ul.sub-menu { padding: 0; min-width: 100%; background: transparent; position: relative; }
	ul.sub-menu li a { padding: 7px 25px !important; font-size: 16px !important; line-height: 20px; }
	.navigation ul li.menu-item-has-children > a:after { content: "\f107"; display: inline-block; margin-left: 5px; font-family: FontAwesome; font-size: 18px;
    font-weight: bold; position: relative; top: 2px; }
	.search-form { width: 100%; }

	.woocommerce.single-product span.onsale { top: 110px; left: 10px; }
	.single-product .product-detail-block .top-nav a { display: block; }
	.site-footer { position: relative; }
	.site-content { margin-bottom: 0 !important; }
	.woocommerce span.onsale { left: 10px; top: -20px; width: 40px; height: 40px; line-height: 45px; font-size: 14px; padding: 0; }
	.single-product .product-detail-block .top-nav li { font-size: 24px; line-height: 28px; }
	.single-product .product-detail-block .top-nav li:after { content: ">"; display: inline-block; margin: 0 12px; vertical-align: middle; }
	.single-product .product-detail-block .top-nav li:last-child:after { content: none; }
	.single-product .product-detail-block .top-nav a { display: inline-block; }

    .product-thumb img { height: 300px; }
}
