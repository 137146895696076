/*-------------------- THEAME CSS ---------------------*/
*,
*:before,
*:after {  box-sizing: inherit; }
html {font-family: sans-serif;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%; box-sizing: border-box; position: relative; min-height: 100%; }
body {	margin: 0;  }
ul,ol { margin: 0 0 20px; padding: 0 0 0 20px; }
ul { list-style: disc; }
ol { list-style: decimal; }
li > ul, li > ol { 	margin-bottom: 0; margin-left: 1.5em; }
img { height: auto; max-width: 100%; }
a { color: #505050; transition: color 0.15s, background-color 0.15s; }
a:hover,a:focus,a:active { text-decoration: none; outline: 0px none; }
hr { border-top-color: #0e3b5f; }
.alignleft { display: inline; float: left; margin-right: 1.5em; }
.alignright { display: inline; float: right; margin-left: 1.5em; }
.aligncenter { clear: both; display: block; margin-left: auto; margin-right: auto; }
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
	content: "";
	display: table;
	table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both;
}

body { font-family:"proxima-nova"; font-size: 20px; color: #252525; line-height: 28px; }
h2 { font-size: 32px; line-height: 38px;font-weight: 900; }
h3 { font-size: 26px; line-height: 26px; font-weight: 700 }
h4 { font-size: 18px; line-height: 22px; font-weight: 700 }
a.button { font-size: 18px; line-height: 22px; color: #AAD7FF; background: #252525; padding: 17px 40px; border-radius: 0; font-weight: 700; text-transform: uppercase; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; }
a.button:hover,a.button:focus { color: #FFF; }
p { margin: 0 0 25px; }

input::-moz-placeholder{ font-size: 16px; color: #4A4A4A; font-weight: 400; font-style: italic; opacity: 1; }
input::-webkit-input-placeholder{ font-size: 16px; color: #4A4A4A; font-weight: 400; font-style: italic; opacity: 1; }
input::-ms-input-placeholder{ font-size: 16px; color: #4A4A4A; font-weight: 400; font-style: italic; opacity: 1; }
input:-ms-input-placeholder{ font-size: 16px; color: #4A4A4A; font-weight: 400; font-style: italic; opacity: 1; }

/*------------ HEADER CSS ---------------*/
.header-top { background-image: linear-gradient(269deg, #206D98 43%, #0E3B5F 95%); }
.logo { padding-top: 20px; padding-bottom: 20px; }
.woo-menu ul { margin: 0; padding: 0; list-style: none; }
.woo-menu ul li { margin: 0 3px;}
.woo-menu ul li:first-child { margin-right: 50px; }
.woo-menu ul li,.woo-menu ul li a { display: inline-block; font-size: 18px; color: #AAD7FF; }
.woo-menu ul li:hover,.woo-menu ul li a:hover { background: #252525; }
.woo-menu ul li a  { padding: 9px 20px 8px; background: #0e3b5f; text-transform: uppercase; }
.woo-menu ul li a .fa { margin-right: 5px; }
.header-address { padding: 20px 5px 30px;}
.header-address p { margin: 0; }
.header-info { color: #FFF; font-size: 18px; color: #FFF; }
.header-info p.call { font-size: 38px; line-height: 46px; }
.header-info p.call a { color: #FFF; font-weight: 700; }

.header-nav { background: #0e3b5f; position: relative; z-index: 999; }
.navigation ul { list-style: none; margin: 0; padding: 0; }
.navigation ul li { display: inline-block; padding: 14px 15px; position: relative; }
.navigation ul li a { padding: 10px; color: #AAD7FF; display: inline-block; text-transform: uppercase; font-weight: 700; transition: color 0.15s; font-size: 20px; line-height: 24px; }
.navigation ul li > a:hover, .navigation ul li.current-menu-ancestor > a, .navigation ul li.current-menu-item > a { color: #FFF; }
.navigation ul li:first-child,.navigation ul li:first-child a { padding-left: 0;}
.navigation ul li:last-child,.navigation ul li:last-child a { padding-right: 0;}
.navigation ul li ul.sub-menu { display: none; position: absolute;top: 100%;left: 0px;width: 100%;min-width: 200px;background-color: #0e3b5f;padding: 5px;z-index: 2;text-align: left;margin-left: 0; }
.navigation ul li.open > ul.sub-menu,.navigation ul li:hover > ul.sub-menu {display: block;}
ul.sub-menu li { display: block; padding: 0 !important; }
ul.sub-menu li a {display: block; padding: 5px 10px !important; font-size: 16px !important; }

/* MEGA MENU */
.navigation ul li .mega-container { background: lighten(#0E3B5F, 5); display: none; left: -999em; padding: 10px; position: absolute; top: -999em; }
.navigation ul li.open > .mega-container,.navigation ul li:hover > .mega-container {display: flex;top: 100%;left: -10px;}
.navigation ul li .mega-container ul.sub-menu { display: block; min-width: 250px; position: static; left: auto; width: auto; background: none; padding: 0 20px 0 0; z-index: auto; }
.navigation ul li .mega-container ul.sub-menu:last-child { padding-right: 0; }
.navigation ul li .mega-container ul.sub-menu li a { border-bottom: 1px solid darken(lighten(#0E3B5F, 5), 5); }
.navigation ul li .mega-container ul.sub-menu li ul.sub-menu { padding-right: 0; }
.navigation ul li .mega-container ul.sub-menu li ul.sub-menu li a { border-bottom: 0; text-transform: none; color: #FFFFFF; }
.navigation ul li .mega-container ul.sub-menu li:not(:last-child) ul.sub-menu li:last-child { margin-bottom: 10px; }


.search-form { padding: 15px; }
.navbar-form { margin: 0; padding: 0; -webkit-box-shadow: none !important; box-shadow: none !important; }
.navbar-form .input-group { border: 1px solid transparent; border-radius: 20px; width: 400px; float: right; }
.search-form input.form-control,.search-form .input-group .input-group-btn .btn { border: 0px; border-radius: 0px; background: transparent; height: 40px; padding: 0 20px; color: #4A4A4A; line-height: 40px; font-size: 16px; box-shadow: none; margin-left: 1px; }
.search-form input.form-control { border-radius: 20px 0 0 20px; background: #FFF;}
.search-form .input-group .input-group-btn { width: 1%; background: #FFF; border-radius: 0 20px 20px 0; }
.input-group .input-group-btn .btn .fa { font-size: 20px; color: #2C4690;}

/*--------------- CONTENT CSS ------------------*/
.entry-title { font-size: 38px; color: #252525; letter-spacing: 0; font-weight: 900; margin: 0 0 55px; }
.content-detail-block { padding: 95px 0 85px; }

/*--------------- FOOTER CSS ------------------*/
.site-footer { background: #252525; padding: 30px 0 25px; position: absolute; bottom: 0; left: 0; right: 0; }
.site-footer ul { margin: 0; padding: 0; list-style: none; }
.site-footer ul li,.site-footer ul li a{ font-size: 16px; color: #AAD7FF; display: inline-block; line-height: 19px;}
.site-footer ul li { color: #9b9b9b; display: block; text-transform: capitalize; }
.footer-links h4 { color: #FFF; margin-bottom: 13px; }
.contact-links ul li a { font-weight: 700; text-transform: lowercase;}
.contact-links ul li:first-child a,.contact-links ul li:nth-child(2) a { color: #FFF; }
.product-categories ul { overflow: hidden; }
.product-categories ul li { float: left; width: 50%; padding-right: 10px; }
.footer-bottom { color: #9b9b9b; font-size: 16px; padding: 33px 0 10px; }
.footer-bottom a { color: #AAD7FF; padding: 0 5px; position: relative; }
.footer-bottom a:before { content: ""; position: relative; display: inline-block; top: 1px; width: 1px; height: 14px; background: #9b9b9b; margin-right: 10px; }
.footer-bottom .weblinx a:before { display: none; }

.product-categories ul li,.service-links ul li { position: relative; }
.product-categories ul li:before,.service-links ul li:before { content: "";  position: relative; display: inline-block; width: 4px; height: 1px; background: #AAD7FF; margin-right: 7px; vertical-align: middle; top: -2px; }
.site-footer ul li a:hover,.footer-bottom a:hover { color: #FFF; }

/*---------------------------- BANNER CSS -------------------*/

.banner-section { background-position: center; background-repeat: no-repeat; background-size: cover; position: relative; padding: 55px 0; }
.banner-slider { display: flex; align-items: center; }
.banner-slider .bx-wrapper { width: 100%; }
.bx-wrapper .bx-viewport { box-shadow: 0 0px 3px 0 rgba(0,0,0,0.50); border: 0px; left: 0; }
ul.bxslider { margin: 0; padding: 0; list-style: none; }
ul.bxslider li { background-position: center; background-repeat: no-repeat; background-size: cover; position: relative; }
ul.bxslider li img { opacity: 0; width: 100%; }

.banner-content { position: absolute; bottom: 45px; left: 0; right: 0; background: rgba(255,255,255,0.7); padding: 20px 35px 30px; font-size: 18px; line-height: 21px; color: #252525;font-weight: 300; font-style: italic; }
.banner-content .banner-title { color: rgba(14,59,95,1); font-size: 38px; font-weight: 900; font-style: normal; margin: 15px 0;}
.banner-content p { margin: 0; }
.banner-right { background-image: linear-gradient(90deg, #206D98 0%, #0E3B5F 84%); box-shadow: 0 4px 4px 0 rgba(0,0,0,0.50); color: #FFF; height: 100%; padding: 45px 30px; font-size: 18px; line-height: 22px; }
.banner-right a { color: rgba(170,215,255,1); font-weight: 700;     word-wrap: break-word; }
.ask-questions:first-child { padding-bottom: 40px; margin-bottom: 40px; border-bottom: 4px solid rgba(170,215,255,1); }
.ask-questions h3 { font-size: 26px; line-height: 26xpx; color: #FFF; margin: 0 0 10px; }
.ask-questions p { margin-bottom: 0; }

/*----------- CATEGORIES CSS --------------*/
.categories-section { background: #f7f7f7; padding: 55px 0; }
.categories-title { position: relative; z-index: 2; padding: 0 35px; }
.categories-title:before { content: ""; position: absolute; top: 50%; left:0; right: 0; height: 4px; width: 100%; background: rgba(170,215,255,1); margin-top: -3px; }
.categories-title h2 { display: inline-block; padding: 0 35px; position: relative; z-index: 2; margin: 0; background: #f7f7f7; }
.home-categories .row { margin-left: -18px; margin-right: -18px; padding-top: 50px;}
.list-category { width: 20%; text-align: center; padding-left: 18px; padding-right: 18px; margin-bottom: 40px; }
.list-category .categories { padding: 0; background: #FFF; box-shadow: inset 0 0 0 0 rgba(255,255,255,0); transition: box-shadow .15s;}
.list-category .categories:hover { box-shadow: inset 0 0 60px 0 rgba(#206D98,0.2); }
.list-category a { display: block; width: 100%; height: 100%; padding: 25px 20px; }
.list-category .categories h2 { font-size: 24px; line-height: 29px; text-transform: uppercase; margin-top: 30px; }
.list-category .categories h2 { color: #0E3B5F; }


/*------------- INTRO CSS ---------------*/
.intro-section { padding: 50px 0; }
.intro-block { padding: 30px 0 10px;}
.intro-block .col-md-4 { padding-left: 60px; }
.sidebar-content { background-image: linear-gradient(269deg, #206D98 43%, #0E3B5F 95%); box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50); color: #FFF; font-size: 18px; line-height: 22px; padding: 35px 30px 30px;}
.sidebar-content h3 { margin: 0 0 10px; }
.sidebar-content p { margin: 0; }
.intro-sidebar a.button { display: block; text-align: center; font-size: 20px; padding: 17px; }

/*------------ PRODUCT LIST PAGE ------------------*/

.productlist-section,.product-detail-block { padding: 95px 0 85px; }
.top-nav { display: none; }
.productlist-section .top-nav,.product-detail-block .top-nav{ display: block; font-size: 30px; color: #216E99; letter-spacing: 0; font-weight: 700; margin: 25px 0 105px; padding: 0 15px; list-style: none;  }
.productlist-section .top-nav li,.product-detail-block .top-nav li { display: inline-block; }
.productlist-section .top-nav li:after, .product-detail-block .top-nav li:after { content: ">"; display: inline-block;
margin: 0 12px; vertical-align: middle; }
.productlist-section .top-nav li:last-child:after, .product-detail-block .top-nav li:last-child:after { content: none;  }
.productlist-section .top-nav a,.product-detail-block .top-nav a { color: #216E99; }
.product-category { text-align: center; margin-bottom: 85px; }
.category-image { position: relative; }
.category-image::after { content: ""; display: block; padding-bottom: 100%; width: 100%; }
.category-image img { object-fit: contain; position: absolute; height: 100% !important; width: 100% !important; left: 0; top: 0; }
.woocommerce img, .woocommerce-page img { width: auto; }
.product-category h3 { font-size: 24px; color: #0E3B5F; font-weight: 900; text-transform: uppercase; margin: 30px 0 10px;}
.intro-sidebar { padding-left: 0; box-shadow: 0 2px 5px 0 rgba(0,0,0,0.50); margin-bottom: 30px; }
.product-list-block .category-sidebar { padding-left: 45px;}
.checkout-edit { color: #AAD7FF; background: #252525; text-align: center; }
.category-sidebar a.button { display: inline-block; text-align: center; font-size: 20px; padding: 17px 0; color: #AAD7FF; background: transparent; font-weight: 700; white-space: normal; text-transform: uppercase; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; border-radius: 0; }
.category-sidebar a.button:hover,.category-sidebar a.button:focus { background: #252525; color: #FFF;  }

.product-list-block .woocommerce-loop-product__title,.up-sells .woocommerce-loop-product__title { font-weight: 700; color: #0E3B5F; padding-top: 25px; }
.product-list-block .woocommerce-Price-amount.amount,.up-sells .woocommerce-Price-amount.amount{ font-size: 18px; color: #454741; font-weight: 900; }

.product-thumb img { height: 220px; }

/*------------- PRODUCT DETAIL CSS -----------*/

.single-product .product-detail-block .top-nav { margin: 35px 0 45px; padding: 0; }
.woocommerce .product-detail-block .product_title { font-size: 38px; color: #252525; letter-spacing: 0; font-weight: 900; margin: 0 0 55px; }
.woocommerce.single-product span.onsale { display: block; width: 60px; height: 60px; line-height: 60px; font-size: 18px; padding: 0; top: 190px; left: -10px; }

.woocommerce #content div.product div.images, .woocommerce div.product div.images, .woocommerce-page #content div.product div.images, .woocommerce-page div.product div.images { float: left; width: 35%; }
.woocommerce div.product div.images .flex-control-thumbs li { margin-right: 10px; width: 24%; }
.woocommerce div.product div.images .flex-control-thumbs li img { border: 1px solid #0e3b5f; }
.woocommerce #content div.product div.summary, .woocommerce div.product div.summary, .woocommerce-page #content div.product div.summary, .woocommerce-page div.product div.summary { float: right; width: 60%; }
.product_meta { margin-bottom: 10px; }
.woocommerce-product-details__short-description { margin-bottom: 35px; }
.woocommerce-product-details__short-description a { color: #337ab7 }
.product-detail-block .sku_wrapper { font-weight: 700 }
.product-detail-block .sku_wrapper .sku { font-weight: 400; }
.woocommerce .product-detail-block table.variations { width: 50% !important; }
.product-detail-block table.variations select { height: 50px; line-height: 100px; border: 1px solid #979797; padding: 0 15px; border-radius: 10px; cursor: pointer; }
.woocommerce div.product form.cart .variations td { vertical-align: middle !important; }
.woocommerce div.product form.cart .variations label { font-size: 20px; color: #252525; letter-spacing: 0; line-height: 48px; font-weight: 400; }
.product-detail-block .screen-reader-text { clip: rect(0,0,0,0); height: auto; overflow: auto; position: relative; width: auto; word-wrap: normal!important; display: inline-block; vertical-align: middle; margin-right: 10px; position: relative !important; font-size: 20px; color: #252525; font-weight: 400; }
input.qty.text { height: 50px; line-height: 50px; border: 1px solid #979797; padding: 0 15px; border-radius: 10px; cursor: pointer; width: 175px; overflow: hidden; }
.upsells.products .product-category { margin: 100px 0; }
.woocommerce.single-product .upsells span.onsale { display: none; }
.woocommerce div.product form.cart .button { background-image: linear-gradient(-1deg, #206D98 12%, #0E3B5F 100%); border-radius: 100px; font-size: 24px; line-height: 26px; text-transform: uppercase; padding: 19px 30px; font-weight: 900; }
.woocommerce div.product form.cart .button i.fa { margin-right: 5px; }

.woocommerce div.product form.cart .variations { float: left; margin: 0; }
.single_variation_wrap, .single_simple_wrap { float: left; width: 100%; }
.woocommerce .product-detail-block .single_variation_wrap p.price,.woocommerce div.product form.cart .single_variation_wrap .button, .woocommerce .product-detail-block .single_simple_wrap p.price,.woocommerce div.product form.cart .single_simple_wrap .button { display: inline-block; float: none; vertical-align: middle; margin: 0 0 20px; }
.woocommerce .product-detail-block .single_variation_wrap p.price, .woocommerce .product-detail-block .single_simple_wrap p.price { font-size: 24px; color: #0E3B5F; letter-spacing: 0; font-weight: 900; margin-right: 30px; }
.woocommerce div.product form.cart div.quantity {     margin: 0 4px 0 10px; }
.woocommerce div.product div.images .flex-control-thumbs { margin: 20px 0 0; }

.up-sells .categories-title h2 { background: #FFF; }

.checkout-edit a.button {display: inline-block;}
.product-detail-block table.variations select:focus,.woocommerce .quantity .qty:focus,.woocommerce div.product form.cart .single_variation_wrap .button:focus, .woocommerce div.product form.cart .single_simple_wrap .button:focus { outline:0px; box-shadow: none; }

.woocommerce .quantity .qty { width: 4.25em; }


/*------------ BULK ORDER FORM ------------------*/

tr.wcbulkorderformtr input { border: 1px solid #999; padding: 4px 8px; margin: 0 0 8px; }


/*------------ QUICK ORDER FORM ------------------*/

tr.wc_bof_product_list .wc_bof_product_name, tr.wc_bof_product_list .wc_bof_product_qty, tr.wc_bof_product_list .wc_bof_product_price { padding: 0 8px 8px 0; vertical-align: top; }
tr.wc_bof_product_list .wc_bof_product_name { width: 40%; }
tr.wc_bof_product_list .wc_bof_product_qty { width: 40%; }
tr.wc_bof_product_list .wc_bof_product_price { width: 40%; }

tr.wc_bof_product_list .selectize-control { display: flex; }
tr.wc_bof_product_list .selectize-input input { width: 100% !important; }
